import React, {useState, useEffect} from "react";

const Header = () => {
    
const [current, setCurrent] = useState("");
const [player, setPlayer] = useState("");
const [live, setLive] = useState("");

useEffect(() => {
  
  setPlayer(new Audio("https://radiopro.com.uy/radio/8020/pyg"));
  const interval = setInterval(() => {
    
     const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json","mode","no-cors");
    
        const requestOptions = {
        headers: myHeaders,
        redirect: 'follow',
        };
    
       
        fetch('https://radiopro.com.uy/api/nowplaying/pyg', requestOptions)
        .then(response => response.json())
        .then(result => {
            setCurrent(result.now_playing.song.text);
        })
        .catch(error => console.log('error', error));
    }, 5000);
    
  return () => clearInterval(interval);

}, []); 

    return(
    <div id="header">
        <div id="logo">
            <img src="../img/web-logo-voxi.png" alt="logo"></img>
        </div>
        <div id="player">
            <div id="controls">
                <div id="play" onClick={()=> {
                    player.play();
                    setLive("live");
                    document.getElementById("play").setAttribute("style","display:none");
                    document.getElementById("play_state").setAttribute("style","display:block");
                    }}><img src="/img/icono-play.png" alt="play"></img></div>
                <div id="play_state"><img src="/img/icono-play-rosa.png" alt="play rosa"></img></div>
            </div>
           <div id="song">{current}</div>
           <div id="volume">
           <input type="range" defaultValue="100" className="volume" onChange={ (e) => player.volume = e.target.value / 100}/>
           </div>
            <div id="sound">
                <div id="mute" onClick={()=> {
                    player.muted = false;
                    document.getElementById("mute").setAttribute("style","display:none");
                    document.getElementById("unmute").setAttribute("style","display:block");
                    }}><img src="/img/icono-mute.png" alt="play"></img>
                </div>
                <div id="unmute" onClick={()=> {
                   player.muted = true;
                    document.getElementById("unmute").setAttribute("style","display:none");
                    document.getElementById("mute").setAttribute("style","display:block");
                    }}><img src="/img/icono-volumen.png" alt="play"></img>
                </div>
            </div>
        </div>
        <div id="social">
            <div className="social_red"><img src="../img/iconos-facebook-voxi.png" alt="facebook"></img></div>
            <div className="social_red"><img src="../img/iconos-twitter-voxi.png" alt="twitter"></img></div>
            <div className="social_red"><a href="https://instagram.com/voxiradio?igshid=YmMyMTA2M2Y=" rel="noreferrer" target="_blank" ><img src="../img/iconos-instagram-voxi.png" alt="instagram"></img></a></div>
            <div className="social_red"><img src="../img/iconos-youtube-voxi.png" alt="youtube"></img></div>
            <div className="clear"></div>
        </div>
         {/*<div id="club"><span>club</span></div>*/}
        <div className="clear"></div>
        <div id="live" className={live}><img src="../img/icono-live.png" alt="live"></img></div>
    </div>
    );
};

export default Header;