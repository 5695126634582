import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Home = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };

        checkMobile();

        window.addEventListener("resize", checkMobile);

        return () => {
            window.removeEventListener("resize", checkMobile);
        };
    }, []);

    return (
        <div id="container">
            <div className="powerby">
                <img src="/img/powerby.png" alt="powerby" />
            </div>
            {/*isMobile ? (
                <video
                    key="mobile-video" 
                    className="phone"
                    width="100%"
                    height="100%"
                    autoPlay
                    muted
                    loop
                >
                    <source src="/video/phone2.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <video
                    key="desktop-video" 
                    width="100%"
                    height="100%"
                    autoPlay
                    muted
                    loop
                >
                    <source src="/video/desktop.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) */}
        </div>
    );
};

export default Home;
